import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, JobCard } from "../components";

const ContactLenses = ({ data: { strapiDsrJobs, allStrapiJobs } }) => {
  const vacancies = allStrapiJobs.edges.map((a) => a.node);
  return (
    <PageLayout
      title={strapiDsrJobs.title}
      intro={strapiDsrJobs.intro}
      flourish={1}
    >
      <section tw="relative pb-8 pt-16 bg-white overflow-hidden">
        <div tw="px-4 relative sm:px-6 lg:px-8">
          <div tw="max-w-prose mx-auto prose prose-lg">
            <div tw="text-xl text-gray-500 leading-8">
              {strapiDsrJobs.intro}
            </div>
            <h2 tw="text-center">Current Vacancies</h2>
          </div>
        </div>
      </section>
      <section tw="mx-auto max-w-screen-xl">
        <div tw="mt-12 max-w-screen-xl mx-auto flex flex-col justify-around items-stretch md:(space-x-5 flex-row) px-4 sm:px-6 lg:px-8 place-items-center">
          {vacancies.map((post) => (
            <JobCard key={post.slug} {...post} />
          ))}
        </div>
      </section>
    </PageLayout>
  );
};

export default ContactLenses;

export const query = graphql`
  query jobsPageQuery {
    strapiDsrJobs {
      content
      intro
      title
    }
    allStrapiJobs(filter: { groups: { elemMatch: { name: { eq: "DSR" } } } }) {
      edges {
        node {
          contactEmail
          content
          updated_at
          location
          slug
          role
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`;
